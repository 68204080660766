<template>
    <div class="visitantes-main ml-5 mr-3">
        <tabs :tabs="tabs" />
        <router-view />
    </div>
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                {
                    titulo: 'Visitantes',
                    ruta: 'visitantes.visitantes',
                },
                {
                    titulo: 'Historial',
                    ruta: 'visitantes.historial',
                },
            ],
        }
    }
}
</script>

<style lang="scss">

</style>